import * as olProj from 'ol/proj';
import proj4 from 'proj4';
import {mapProjEPSG} from './projection_setup'

import GeoJSON from 'ol/format/GeoJSON';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import Feature from 'ol/Feature'

import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';

import {Layer, Group as LayerGroup} from 'ol/layer';
import { Icon } from 'ol/style';

const {fillLayerOpacityChange, getFillLayerOpacity,setLayerOpacityMap} = require('./mapStyles')

const { legendImages, getLegendImage, mapStyles,getLayerOpacity, layerOpacityChange } = require('./mapStyles')
var proj = olProj.get(mapProjEPSG);

const geoJSONMapProj = new GeoJSON({dataProjection:proj, featureProjection:proj});

let systemBase = '.';

//Dev Mode
let hostSubStr = window.location.host.substring(0,9)

//window.location.protocol not http https
if(hostSubStr == "127.0.0.1" || hostSubStr== "localhost" || window.location.protocol.substring(0,4) != 'http' ){
  systemBase = 'https://cwfhtest.eskmapping.com.au';
}

var opacityMap = new Map();


export const CWFHLayerGroup = new LayerGroup({
    title: 'CWFH',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    visible: true
});


const customers = new VectorLayer({
    title: 'Customers',
    legendImageSrc: legendImages.customer,
    style: mapStyles.customerStyle,
    source: new VectorSource({
        format: geoJSONMapProj,
        projection: proj,
        url: './static/Customers.geojson'
    }),
    updateWhileAnimating: true,
    zIndex:999
});


export const otherSoftwood = new VectorLayer({
    title: 'Other Softwood',
    style: (feature, resolution) => {return mapStyles.otherSoftwoodStyle(feature,resolution)},
    source: new VectorSource({
        format: geoJSONMapProj,
        projection: proj,
        url: './static/Other_Softwood.geojson'
    }),
    updateWhileAnimating: true,
    zIndex:999
});

otherSoftwood.setProperties({'legendImageSrc': getLegendImage(otherSoftwood,'Polygon',new Feature({Area_Ha:1}))});



CWFHLayerGroup.getLayers().push(otherSoftwood);


const hubBoundary = new VectorLayer({
    title: 'Boundary',
    // legendImageSrc: getLegendImage(hubBoundary),
    style: mapStyles.hubBoundaryStyle,
    source: new VectorSource({
        format: geoJSONMapProj,
        projection: proj,
        url: './static/CWFH_Boundary.geojson'
    }),
    updateWhileAnimating: true,
    zIndex:999
});

hubBoundary.setProperties({'legendImageSrc': getLegendImage(hubBoundary)});

CWFHLayerGroup.getLayers().push(hubBoundary);

CWFHLayerGroup.getLayers().push(customers);


export const softwoodGroup = new LayerGroup({
    title: 'Softwood',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    visible: true
});

const softwoodByOwnerGroup = new LayerGroup({
    title: 'By Owner',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: true
});

const softwoodByRotationGroup = new LayerGroup({
    title: 'By Rotation',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: false
});

const softwoodByAgeGroup = new LayerGroup({
    title: 'By Age',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: false
});

const softwoodByAgeRotationGroup = new LayerGroup({
    title: 'By Age  & Rotation',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: false
});

const haulageGroup = new LayerGroup({
    title: 'Haulage',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    visible: false
});

const haulageVol2041Group = new LayerGroup({
    title: 'Volume Summary 2041-2050',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: false
});


const haulageVol2031Group = new LayerGroup({
    title: 'Volume Summary 2031-2040',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: false
});


const haulageVol2021Group = new LayerGroup({
    title: 'Volume Summary 2021-2030',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: true
});



haulageGroup.getLayers().push(haulageVol2021Group);
haulageGroup.getLayers().push(haulageVol2031Group);
haulageGroup.getLayers().push(haulageVol2041Group);

softwoodGroup.getLayers().push(softwoodByAgeRotationGroup);
softwoodGroup.getLayers().push(softwoodByAgeGroup);
softwoodGroup.getLayers().push(softwoodByRotationGroup);
softwoodGroup.getLayers().push(softwoodByOwnerGroup);

softwoodByOwnerGroup.on('change:visible',softwoodChangeVisibleGroup);
softwoodByRotationGroup.on('change:visible',softwoodChangeVisibleGroup);
softwoodByAgeGroup.on('change:visible',softwoodChangeVisibleGroup);
softwoodByAgeRotationGroup.on('change:visible',softwoodChangeVisibleGroup);


haulageVol2021Group.on('change:visible',haulageChangeVisibleGroup);
haulageVol2031Group.on('change:visible',haulageChangeVisibleGroup);
haulageVol2041Group.on('change:visible',haulageChangeVisibleGroup);


const haulageVolSource = new VectorSource({
    format: geoJSONMapProj,
    projection: proj,
    url: './static/Haulage.geojson'
});

haulageGroup.on('change:visible',displayLegend);

function displayLegend(e)
{
    if(e.oldValue == false)
    {
        document.getElementById("legend").style.display = "flex";
    }
    else
    {
        document.getElementById("legend").style.display = "none";
    }

   
}

CWFHLayerGroup.getLayers().push(haulageGroup);

const softwoodVTSource = new VectorTileSource({
    format: new MVT(),
    url: systemBase+"/vt/softwood_simple/{z}/{x}/{y}.pbf",
    minZoom: 0,
    maxZoom: 15
});

const haulageTotals21 = ['0 - 50,000', '50,001 - 100,000', '100,001 - 500,000', '500,001 - 1,000,000', '1,000,001 - 2,000,000', '2,000,001 - 3,000,000', '3,000,001 - 4,000,000', '4,000,001 - 5,000,000', '5,000,001 - 6,000,000','6,000,001 - 6,500,000'];


haulageTotals21.forEach(t => {

    let mapkey = "Volume Summary 2021-2030" + t;

    opacityMap.set(mapkey,Math.random() * (1 - 0) + 0);
    setLayerOpacityMap(opacityMap);

    let layer = new VectorLayer({
        title: t,
        enableOpacitySliders: true,
        opacityLabel: "Opacity",
        style: (feature, resolution)=>{ return mapStyles.haulageBytotalVolume(feature, resolution, 'Volume Summary 2021-2030', t ) },
        source: haulageVolSource,
        updateWhileAnimating: true,
        zIndex:999
})
haulageVol2021Group.getLayers().push(layer);
});


const haulageTotals31 = ['0 - 50,000', '50,001 - 100,000', '100,001 - 500,000', '500,001 - 1,000,000', '1,000,001 - 2,000,000', '2,000,001 - 3,000,000', '3,000,001 - 4,000,000', '4,000,001 - 5,000,000', '5,000,001 - 6,000,000','6,000,001 - 7,000,000'];

haulageTotals31.forEach(t => {

    let mapkey = "Volume Summary 2031-2040" + t;

    opacityMap.set(mapkey,Math.random() * (1 - 0) + 0);
    setLayerOpacityMap(opacityMap);

    let layer = new VectorLayer({
        title: t,
        enableOpacitySliders: true,
        opacityLabel: "Opacity",
        style: (feature, resolution)=>{ return mapStyles.haulageBytotalVolume(feature, resolution, 'Volume Summary 2031-2040', t ) },
        source: haulageVolSource,
        updateWhileAnimating: true,
        zIndex:999
})
haulageVol2031Group.getLayers().push(layer);
});

const haulageTotals41 = ['0 - 50,000', '50,001 - 100,000', '100,001 - 500,000', '500,001 - 1,000,000', '1,000,001 - 2,000,000', '2,000,001 - 3,000,000', '3,000,001 - 4,000,000', '4,000,001 - 5,000,000', '5,000,001 - 6,000,000','6,000,001 - 7,000,000','7,000,001 - 8,000,000','8,000,001 - 9,000,000','9,000,001 - 10,000,000'];

haulageTotals41.forEach(t => {
    let mapkey = "Volume Summary 2041-2050" + t;

    opacityMap.set(mapkey,Math.random() * (1 - 0) + 0);
    setLayerOpacityMap(opacityMap);


    let layer = new VectorLayer({
        title: t,
        enableOpacitySliders: true,
        opacityLabel: "Opacity",
        style: (feature, resolution)=>{ return mapStyles.haulageBytotalVolume(feature, resolution, 'Volume Summary 2041-2050', t ) },
        source: haulageVolSource,
        updateWhileAnimating: true,
        zIndex:999
})
haulageVol2041Group.getLayers().push(layer);
});


//Reverese order as added from then bottom up, and in legend as top down
const softwoodSources = ['Private', 'PPP', 'Hume','FCNSW','FCNSW JV'];

softwoodSources.forEach(s=>{
    opacityMap.set(s,1);
    setLayerOpacityMap(opacityMap);

    let svg = mapStyles.plantationStyleByOwnerLegend(s);

    let layer = new VectorTileLayer({
        title: s,
        enableOpacitySliders: true,
        onOpacityChange: fillLayerOpacityChange,
        getOpacityOveride: getFillLayerOpacity,
        source: softwoodVTSource,
        style:  (feature, resolution)=>{ return mapStyles.plantationStyleByOwner(feature, resolution, s) },
        visible: true,
        legendImageSrc: svg,
    });

    softwoodByOwnerGroup.getLayers().push(layer);
    
});


const rotations = [4,3,2,1,0];

rotations.forEach(rotation=>{

    let mapkey = rotation + "R";

    opacityMap.set(mapkey,1);
    setLayerOpacityMap(opacityMap);
    

    let svg = mapStyles.plantationStyleByRotationLegend(rotation+"R");

    let layer = new VectorTileLayer({
        title: ''+rotation +"R",
        enableOpacitySliders: true,
        onOpacityChange: fillLayerOpacityChange,
        getOpacityOveride: getFillLayerOpacity,
        source: softwoodVTSource,
        style:  (feature, resolution)=>{ return mapStyles.plantationStyleByRotation(feature, resolution,mapkey) },
        visible: true,
        legendImageSrc: svg,
    });

    softwoodByRotationGroup.getLayers().push(layer);
});

const age = ["Clearfell in Progress","1990 & older","1991-2001","2001-2010","2011-2021","Planting in Progress","Unplanted","Regrowth"];

age.forEach(age=>{

    let svg = mapStyles.plantationStyleByAgeLegend(age);
    let mapkey = age;

    opacityMap.set(mapkey,1);
    setLayerOpacityMap(opacityMap);

    let layer = new VectorTileLayer({
        title: age,
        enableOpacitySliders: true,
        onOpacityChange: fillLayerOpacityChange,
        getOpacityOveride: getFillLayerOpacity,
        opacityLabel: "Opacity",
        source: softwoodVTSource,
        style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAge(feature, resolution, age) },
        visible: true,
        legendImageSrc: svg,
    });

    softwoodByAgeGroup.getLayers().push(layer);
});


const ageRotation = ["Other","1990 & older","1991-2001","2001-2010","2011-2021"];

ageRotation.forEach(ageRotation => {

    rotations.forEach(rotation => {
 
        if(ageRotation == "2011-2021")
        {
            if(rotation > 0 && rotation < 4)
            {
                let mapkey = ageRotation +','+rotation + 'R';

                opacityMap.set(mapkey,1);
                setLayerOpacityMap(opacityMap);
                
                 var layer = new VectorTileLayer({
                    title: ageRotation +','+rotation + 'R',
                    enableOpacitySliders: true,
                    onOpacityChange: fillLayerOpacityChange,
                    getOpacityOveride: getFillLayerOpacity,
                    opacityLabel: "Opacity",
                    source: softwoodVTSource,
                    style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, rotation+"R",ageRotation) },
                    visible: true
                });

                layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation:rotation+"R", agegroup:ageRotation }))});
            }
        }
        else if (ageRotation == "2001-2010")
        {
            let mapkey = ageRotation +','+rotation + 'R';

            opacityMap.set(mapkey,1);
            setLayerOpacityMap(opacityMap);

            var layer = new VectorTileLayer({
                title: ageRotation +','+rotation + 'R',
                enableOpacitySliders: true,
                onOpacityChange: fillLayerOpacityChange,
                getOpacityOveride: getFillLayerOpacity,
                opacityLabel: "Opacity",
                source: softwoodVTSource,
                style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, rotation+"R",ageRotation) },
                visible: true
            });

            layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation:rotation+"R", agegroup:ageRotation }))});
        }
        else if (ageRotation == "1991-2001")
        {
            if(rotation < 3)
            {
                let mapkey = ageRotation +','+rotation + 'R';

                opacityMap.set(mapkey,1);
                setLayerOpacityMap(opacityMap);

               var  layer = new VectorTileLayer({
                    title: ageRotation +','+rotation + 'R',
                    enableOpacitySliders: true,
                    onOpacityChange: fillLayerOpacityChange,
                    getOpacityOveride: getFillLayerOpacity,
                    opacityLabel: "Opacity",
                    source: softwoodVTSource,
                    style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, rotation+"R",ageRotation) },
                    visible: true
                });

                layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation:rotation+"R", agegroup:ageRotation }))});
            }

        }

        else if(ageRotation == "1990 & older")
        {
            if(rotation > 0 && rotation < 4)
            {
                let mapkey = ageRotation +','+rotation + 'R';

                opacityMap.set(mapkey,1);
                setLayerOpacityMap(opacityMap);

               var layer = new VectorTileLayer({
                    title: ageRotation +','+rotation + 'R',
                    enableOpacitySliders: true,
                    onOpacityChange: fillLayerOpacityChange,
                    getOpacityOveride: getFillLayerOpacity,
                    opacityLabel: "Opacity",
                    source: softwoodVTSource,
                    style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, rotation+"R",ageRotation) },
                    visible: true
                });
                layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation:rotation+"R", agegroup:ageRotation }))});
            }
        }
        

        if(typeof layer != 'undefined' )
        softwoodByAgeRotationGroup.getLayers().push(layer);
    })

 if (ageRotation == "Other")
        {
            let mapkey = "Other";

            opacityMap.set(mapkey,100);
            setLayerOpacityMap(opacityMap);

             var layer = new VectorTileLayer({
                title: "Other",
                enableOpacitySliders: true,
                onOpacityChange: fillLayerOpacityChange,
                getOpacityOveride: getFillLayerOpacity,
                opacityLabel: "Opacity",
                source: softwoodVTSource,
                style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, "Other", "Other") },
                visible: true
            });

            layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation: "Other", agegroup: "Other" }))});
        }
        if(typeof layer != 'undefined' )
        softwoodByAgeRotationGroup.getLayers().push(layer);

});

const roadsGroup = new LayerGroup({
    title: 'Roads',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    visible: false
});

const rodsVTSource = new VectorTileSource({
    format: new MVT(),
    url: systemBase+"/vt/roads/{z}/{x}/{y}.pbf",
    minZoom: 0,
    maxZoom: 15
});


    
const sealedRoads = new VectorTileLayer({
    title: 'Sealed',
    source: rodsVTSource,
    style:  (feature, resolution)=>{ return mapStyles.roadStyle(feature, resolution, 1) },
    visible: true
});

const gravelRoads = new VectorTileLayer({
    title: 'Gravel',
    source: rodsVTSource,
    style:  (feature, resolution)=>{ return mapStyles.roadStyle(feature, resolution, 2) },
    visible: true
});

const naturalRoads = new VectorTileLayer({
    title: 'Natural Surface',
    source: rodsVTSource,
    style:  (feature, resolution)=>{ return mapStyles.roadStyle(feature, resolution, 3) },
    visible: false
});


sealedRoads.setProperties({'legendImageSrc': getLegendImage(sealedRoads, 'LineString', new Feature({surface:1}))});
gravelRoads.setProperties({'legendImageSrc': getLegendImage(gravelRoads, 'LineString', new Feature({surface:2}))});
naturalRoads.setProperties({'legendImageSrc': getLegendImage(naturalRoads, 'LineString', new Feature({surface:3}))});

haulageVol2021Group.getLayers().forEach((layer) => {

    var title = layer.values_.title;
    let cleanedTitle = title.replace(/ /g, "").replace(/,/g,"").split("-");
    var lastValue = cleanedTitle[1];

   

    layer.setProperties({'legendImageSrc': getLegendImage(layer, 'LineString', new Feature({TOT_VOL_20:parseInt(lastValue)}))});

    

});

generateHaulageLegendPopup(haulageVol2021Group);


haulageVol2031Group.getLayers().forEach((layer) => {

    var title = layer.values_.title;
    let cleanedTitle = title.replace(/ /g, "").replace(/,/g,"").split("-");
    var lastValue = cleanedTitle[1];

    layer.setProperties({'legendImageSrc': getLegendImage(layer, 'LineString', new Feature({TOT_VOL_21:parseInt(lastValue)}))});

});

haulageVol2041Group.getLayers().forEach((layer) => {

    var title = layer.values_.title;
    let cleanedTitle = title.replace(/ /g, "").replace(/,/g,"").split("-");
    var lastValue = cleanedTitle[1];

    layer.setProperties({'legendImageSrc': getLegendImage(layer, 'LineString', new Feature({TOT_VOL_22:parseInt(lastValue)}))});

});


roadsGroup.getLayers().push(naturalRoads);
roadsGroup.getLayers().push(gravelRoads);
roadsGroup.getLayers().push(sealedRoads);


CWFHLayerGroup.getLayers().push(roadsGroup);
CWFHLayerGroup.getLayers().push(softwoodGroup);




//Mutx for softwood group layers
function softwoodChangeVisibleGroup(event){
    let changedGroup = event.target

    if(!changedGroup.getVisible()) return;
    
    softwoodGroup.getLayers().getArray().forEach(l=>{
        if(l != changedGroup){
            l.setVisible(false);
        }
    });
}


function haulageChangeVisibleGroup(event){
    let changedGroup = event.target

    if(!changedGroup.getVisible()) return;
    
    haulageGroup.getLayers().getArray().forEach(l=>{
        if(l != changedGroup){
            l.setVisible(false);
        }
        else
        {
            generateHaulageLegendPopup(l)
        }
    });
}

function generateHaulageLegendPopup(layerGroup)
{
    let legendPopup = document.getElementById("legend");
    legendPopup.innerHTML = '';

    layerGroup.getLayers().forEach((layer) => 
    {

        var title = layer.values_.title;
        let cleanedTitle = title.replace(/ /g, "").replace(/,/g,"").split("-");
        var lastValue = cleanedTitle[1];
        let splitTitle = title.replace('-','').replace(" ", "").split(" ");

        let container = document.createElement("div");
        container.className = "flex-container center";
        
        let upperText = document.createElement("p");
        
        upperText.textContent = splitTitle[0];

        let lowerText = document.createElement("p");
        
        lowerText.textContent = splitTitle[1];
        
        let img = document.createElement("img");

        if(layerGroup.values_.title.includes("2021"))
        img.src = getLegendImage(layer, 'LineString', new Feature({TOT_VOL_20:parseInt(lastValue)}));
        if(layerGroup.values_.title.includes("2031"))
        img.src = getLegendImage(layer, 'LineString', new Feature({TOT_VOL_21:parseInt(lastValue)}));
        if(layerGroup.values_.title.includes("2041"))
        img.src = getLegendImage(layer, 'LineString', new Feature({TOT_VOL_22:parseInt(lastValue)}));

        container.appendChild(upperText);
        container.appendChild(img);
        container.appendChild(lowerText);
        legendPopup.appendChild(container);
    })
}
