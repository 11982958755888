// Popper Help
//
// Semi reusable ---
//
// Author Daniel Livingston

import Overlay from 'ol/Overlay';

import { v4 as uuidv4 } from 'uuid';

import { Popover } from 'bootstrap';

import '../style/map.sass'
import { disable } from 'ol/rotationconstraint';

var $ = require("jquery");

const CLOSE_ID = 'popper_help_close';
const CLOSE_ID2 = 'popper_help_close2';
const COUNT_ID = 'popper_help_count';
const NEXT_ID = 'popper_help_next';
const PREVIOUS_ID = 'popper_help_previous';

export class PopperHelpSet {

    constructor(init_options) {
        const defaults = {
            activeIndex: null,
            id: uuidv4(),
            elementIDs: [],
            elementOptions: {},
            template: '\
<div class="popover popover-help-set" role="tooltip">\
    <div class="popover-arrow"></div>\
    <div class="popover-header-block"><i class="dummy button far fa-window-close"></i><h3 class="popover-header"></h3><i id="'+CLOSE_ID+'" class="button far fa-window-close"></i></div><div class="popover-body"></div>\
    <div class="popover-foot">\
        <div id="'+PREVIOUS_ID+'" class="button">Previous</div>\
        <div id="'+COUNT_ID+'"></div>\
        <div id="'+NEXT_ID+'" class="button">Next</div>\
        <div id="'+CLOSE_ID2+'" class="button hidden">Close</div>\
    </div>\
</div>',
            popover:null
        };

        var options = Object.assign({}, defaults, init_options);

        this.activeIndex;
        this.id = options.id;
        this.elementIDs = options.elementIDs
        this.elementOptions = options.elementOptions;
        this.dismissCallback = options.dismissCallback;
        this.popover = options.popover;
        this.template = options.template;

        $(document).on('click', '#'+NEXT_ID, e=>{this.nextElement()});
        $(document).on('click', '#'+PREVIOUS_ID, e=>{this.previousElement()});
        //regex
        $(document).on('click', '#'+CLOSE_ID+' ,#'+CLOSE_ID2, e=>{
            this.hide();
            this.activeIndex = null;
            if(this.dismissCallback) this.dismissCallback();
        });
    }

    addElement(elementID, options ={}, index=null){
        if(this.elementIDs.includes(elementID)){
            //Update options? merge? replace?
        }
        else{
            index = index??this.elementIDs.length;
            this.elementIDs.splice(index, 0, elementID);
            options.template = options.template??this.template;
            this.elementOptions[elementID] = options;
        } 
    }


    removeElement(elementID){
        if(this.elementIDs[this.activeIndex] == elementID){
            //TODO
        }
        if(this.elementIDs.includes(elementID)){
            delete this.elementOptions[elementID];
            this.elementIDs.remove(elementID);
        }
        else{
            //TODO?
        }
    }


    hide(){
        if(this.activeIndex){
            let activeElementOptions = this.elementOptions[this.elementIDs[this.activeIndex]];
            if(activeElementOptions.preHideCallback) activeElementOptions.preHideCallback();
            if(this.popover){
                this.popover.hide();
                this.popover.disable();
            }
            if(activeElementOptions.postHideCallback) activeElementOptions.postHideCallback();
        }
        else{
            if(this.popover){
                this.popover.hide();
                this.popover.disable();
            }
        } 
    }


    nextElement(){
        let newIndex = typeof(this.activeIndex) === 'number' ? (this.activeIndex + 1) : 0;
        this.showElement(newIndex, this.activeIndex);
    }
    
    previousElement(){
        //wrap? mark end end diasbale button?
        let newIndex = typeof(this.activeIndex) === 'number' ? (this.activeIndex - 1) : 0;
        //TODO check if valid etc
        this.showElement(newIndex, this.activeIndex);
    }

    showElement(newIndex=0, oldIndex=null){
        let elementId = this.elementIDs[newIndex];
        let element = document.getElementById(elementId);
        if(!element){
            throw 'Invalid element';
        } 
        let elementOptions = this.elementOptions[elementId];
        let previousElementOptions = {};
        if(oldIndex){
            previousElementOptions = this.elementOptions[this.elementIDs[oldIndex]];
        }

        let newPopover = this._generatePopover(element, elementOptions);

        if(previousElementOptions.preHideCallback) previousElementOptions.preHideCallback();
        if(this.popover){
            this.popover.hide();
            this.popover.disable();
        }
        if(previousElementOptions.postHideCallback) previousElementOptions.postHideCallback();
        this.popover = newPopover;
        this._show(elementOptions.preShowCallback, elementOptions.postShowCallback);
        this.activeIndex = newIndex;
    }

    startHelp(){
        try{
            this.hide();
        }catch(e){}
        
        this.showElement(0);
    }

    _generatePopover(element, options){
        let newPopover = new Popover(element, {
            placement: options.popper_placement??'bottom',
            animation: true,
            html: true,
            content: options.content,
            template: options.template,
            title: (options.title??'Help')
        });

        return newPopover;
    }

    _show(preCallback, postCallback){
        if(preCallback) preCallback();

        this.popover.show();

        //no actual post show event???
        setTimeout(()=>{
            $('#'+COUNT_ID).empty().append(''+ (this.activeIndex +1) + ' of ' + this.elementIDs.length);

            if(this.activeIndex === (this.elementIDs.length -1)){
                $('#'+NEXT_ID).addClass('hidden');
                $('#'+CLOSE_ID2).removeClass('hidden');
            }else{
                $('#'+NEXT_ID).removeClass('hidden');
                $('#'+CLOSE_ID2).addClass('hidden');
            }
            if(this.activeIndex === 0){
                $('#'+PREVIOUS_ID).addClass('disable');
            }else{
                $('#'+PREVIOUS_ID).removeClass('disable');
            }
        }, 100);

        if(postCallback) postCallback();
    }

    update(){
        this.popover.update();
    }
}

export default PopperHelpSet;