// https://github.com/walkermatt/ol-layerswitcher
//
// MIT (c) Matt Walker.
//
// Mod Daniel Livingston

const CSS_PREFIX = 'layer-switcher-';

(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory(require('ol/control/Control'), require('ol/Observable')) :
    typeof define === 'function' && define.amd ? define(['ol/control/Control', 'ol/Observable'], factory) :
    (global.LayerSwitcher = factory(global.ol.control.Control,global.ol.Observable));
}(this, (function (Control,Observable) { 'use strict';

Control = 'default' in Control ? Control['default'] : Control;
Observable = 'default' in Observable ? Observable['default'] : Observable;

var classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();


var get = function get(object, property, receiver) {
  if (object === null) object = Function.prototype;
  var desc = Object.getOwnPropertyDescriptor(object, property);

  if (desc === undefined) {
    var parent = Object.getPrototypeOf(object);

    if (parent === null) {
      return undefined;
    } else {
      return get(parent, property, receiver);
    }
  } else if ("value" in desc) {
    return desc.value;
  } else {
    var getter = desc.get;

    if (getter === undefined) {
      return undefined;
    }

    return getter.call(receiver);
  }
};

var inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};











var possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
};



/**
 * OpenLayers Layer Switcher Control.
 * See [the examples](./examples) for usage.
 * @constructor
 * @extends {ol.control.Control}
 * @param {Object} opt_options Control options, extends olx.control.ControlOptions adding:  
 * **`tipLabel`** `String` - the button tooltip.
 */

var LayerSwitcher = function (_Control) {
    inherits(LayerSwitcher, _Control);

    function LayerSwitcher(opt_options) {
        classCallCheck(this, LayerSwitcher);


        var options = opt_options || {};

        var tipLabel = options.tipLabel ? options.tipLabel : 'Legend';

        var element = document.createElement('div');

        var _this = possibleConstructorReturn(this, (LayerSwitcher.__proto__ || Object.getPrototypeOf(LayerSwitcher)).call(this, { element: element, target: options.target }));

        _this.mapListeners = [];

        _this.hiddenClassName = 'ol-unselectable ol-control layer-switcher';
        if (LayerSwitcher.isTouchDevice_()) {
            _this.hiddenClassName += ' touch';
        }
        _this.shownClassName = 'shown';

        element.className = _this.hiddenClassName;

        var button = document.createElement('button');
        button.setAttribute('title', tipLabel);
        element.appendChild(button);

        _this.panel = document.createElement('div');
        _this.panel.className = 'panel';
        element.appendChild(_this.panel);
        LayerSwitcher.enableTouchScroll_(_this.panel);

        var this_ = _this;

        button.onmouseover = function (e) {
            this_.showPanel();
        };

        button.onclick = function (e) {
            e = e || window.event;
            this_.showPanel();
            e.preventDefault();
        };

        this_.panel.onmouseout = function (e) {
            e = e || window.event;
            if (!this_.panel.contains(e.toElement || e.relatedTarget)) {
                this_.hidePanel();
            }
        };

        this_.onOpacityChange = options.onOpacityChange || null;
        this_.onLayerToggle = options.onLayerToggle || null;
        this_.legendImageSrc = options.legendImageSrc || null;
        this_.extraDivConfig = options.extraDivConfig || null;

        return _this;
    }

    /**
    * Set the map instance the control is associated with.
    * @param {ol.Map} map The map instance.
    */


    createClass(LayerSwitcher, [{
        key: 'setMap',
        value: function setMap(map) {
            // Clean up listeners associated with the previous map
            for (var i = 0, key; i < this.mapListeners.length; i++) {
                Observable.unByKey(this.mapListeners[i]);
            }
            this.mapListeners.length = 0;
            // Wire up listeners etc. and store reference to new map
            get(LayerSwitcher.prototype.__proto__ || Object.getPrototypeOf(LayerSwitcher.prototype), 'setMap', this).call(this, map);
            if (map) {
                var this_ = this;
                this.mapListeners.push(map.on('pointerdown', function () {
                    this_.hidePanel();
                }));
                this.renderPanel();
            }
        }

        /**
        * Show the layer panel.
        */

    }, {
        key: 'showPanel',
        value: function showPanel() {
            if (!this.element.classList.contains(this.shownClassName)) {
                this.element.classList.add(this.shownClassName);
                this.renderPanel();
            }
        }

        /**
        * Hide the layer panel.
        */

    }, {
        key: 'hidePanel',
        value: function hidePanel() {
            if (this.element.classList.contains(this.shownClassName)) {
                this.element.classList.remove(this.shownClassName);
            }
        }

        /**
        * Re-draw the layer panel to represent the current state of the layers.
        */

    }, {
        key: 'renderPanel',
        value: function renderPanel() {
            LayerSwitcher.renderPanel(this.getMap(), this.panel);
        }

        /**
        * **Static** Re-draw the layer panel to represent the current state of the layers.
        * @param {ol.Map} map The OpenLayers Map instance to render layers for
        * @param {Element} panel The DOM Element into which the layer tree will be rendered
        */

    }], [{
        key: 'renderPanel',
        value: function renderPanel(map, panel) {

            LayerSwitcher.ensureTopVisibleBaseLayerShown_(map);

            while (panel.firstChild) {
                panel.removeChild(panel.firstChild);
            }

            var ul = document.createElement('ul');
            panel.appendChild(ul);
            // passing two map arguments instead of lyr as we're passing the map as the root of the layers tree
            LayerSwitcher.renderLayers_(map, map, ul);
        }

        /**
        * **Static** Ensure only the top-most base layer is visible if more than one is visible.
        * @param {ol.Map} map The map instance.
        * @private
        */

    }, {
        key: 'ensureTopVisibleBaseLayerShown_',
        value: function ensureTopVisibleBaseLayerShown_(map) {
            var lastVisibleBaseLyr;
            LayerSwitcher.forEachRecursive(map, function (l, idx, a) {
                if (l.get('type') === 'base' && l.getVisible()) {
                    lastVisibleBaseLyr = l;
                }
            });
            if (lastVisibleBaseLyr) LayerSwitcher.setVisible_(map, lastVisibleBaseLyr, true);
        }

        /**
        * **Static** Toggle the visible state of a layer.
        * Takes care of hiding other layers in the same exclusive group if the layer
        * is toggle to visible.
        * @private
        * @param {ol.Map} map The map instance.
        * @param {ol.layer.Base} The layer whos visibility will be toggled.
        */

    }, {
        key: 'setVisible_',
        value: function setVisible_(map, lyr, visible) {
            lyr.setVisible(visible);
            if (visible && lyr.get('type') === 'base') {
                // Hide all other base layers regardless of grouping
                LayerSwitcher.forEachRecursive(map, function (l, idx, a) {
                    if (l != lyr && l.get('type') === 'base') {
                        l.setVisible(false);
                    }
                });
            }
        }

        /**
        * **Static** Render all layers that are children of a group.
        * @private
        * @param {ol.Map} map The map instance.
        * @param {ol.layer.Base} lyr Layer to be rendered (should have a title property).
        * @param {Number} idx Position in parent group list.
        */

    }, {
        key: 'renderLayer_',
        value: function renderLayer_(map, lyr, idx) {

            var li = document.createElement('li');

            var lyrTitle = lyr.get('title');
            var lyrId = LayerSwitcher.uuid();

            var label = document.createElement('label');

            if (lyr.getLayers && !lyr.get('combine')) {

                //if the layer group has Hide In Legend set to True, we will skip creating Li objects for that group. 
                if(!lyr.get('hideInLegend'))
                {
                    var ul = document.createElement('ul');

                    li = GenerateLayerSubList(lyr,lyrId,lyrTitle,li,label,ul);
    
                    LayerSwitcher.renderLayers_(map, lyr, ul);
                }

            } else {
                if(lyr.get('hideInLegend')){
                    //skip
                }
                else{
                    li.className = 'layer';
                    var container = document.createElement('div');
                    container.className = "layer-settings"

                    var input = document.createElement('input');
                    
                    if (lyr.get('type') === 'base') {
                        input.type = 'radio';
                        input.name = 'base';
                    } else {
                        input.type = 'checkbox';
                    }
                    input.id = lyrId;
                    input.checked = lyr.get('visible');
                    input.onchange = function (e) {
                        LayerSwitcher.setVisible_(map, lyr, e.target.checked);
                    };

                    lyr.on('change:visible', function(e){
                        input.checked = !e.oldValue
                        //Quick work around for parent.
                        if(input.checked){
                            try{
                                var groupInput = input.parentElement.parentElement.parentElement.parentElement.children[0];
                                groupInput.checked = true;
                                groupInput.onchange({target: groupInput});
                            }
                            catch(e){}
                        }
                    });

                    container.appendChild(input);


                    var legendImageSrc = lyr.get('legendImageSrc');
                    if(legendImageSrc){
                        var img = document.createElement('img');
                        img.src= legendImageSrc;
                        container.appendChild(img);
                    }

                    label.htmlFor = lyrId;
                    label.innerHTML = lyrTitle;

                    var rsl = map.getView().getResolution();
                    if (rsl > lyr.getMaxResolution() || rsl < lyr.getMinResolution()) {
                        label.className += ' disabled';
                    }

                    container.appendChild(label);

                    // opacity slider (true|false)
                    if (lyr.get('enableOpacitySliders')) {
                        var opacity_container = document.createElement('div');
                        opacity_container.className = 'layer_opacity_settings';
                        var input_o = document.createElement('input');
                        input_o.name = 'layer_opacity_'+lyrId;
                        input_o.className = 'layer_opacity';
                        input_o.type = 'range';
                        input_o.min = 0;
                        input_o.max = 1;
                        input_o.step = 0.01;
                        if(lyr.get('getOpacityOveride')){
                            input_o.value = lyr.get('getOpacityOveride')(lyr);
                        }
                        else{
                            input_o.value = lyr.getOpacity(lyr);
                        }
                        
                        if (lyr.get('onOpacityChange')){
                            input_o.onOpacityChange = lyr.get('onOpacityChange')
                        }

                        input_o.onchange = function (e) {
                            if (this.onOpacityChange !== null && typeof this.onOpacityChange === "function") {
                                this.onOpacityChange(parseFloat(e.target.value), lyr);
                            }
                            else{
                                var a = lyr.getSource();
                               lyr.setOpacity(parseFloat(e.target.value));
                            }
                        };
                        input_o.oninput = input_o.onchange;

                        var opacityLabelText = lyr.get('opacityLabel')
                        if (opacityLabelText && opacityLabelText.length > 0){
                            var opacity_label = document.createElement('label');
                            opacity_label.htmlFor = input_o.name;
                            opacity_label.innerHTML = opacityLabelText;
                            opacity_label.className = 'layer_opacity_label';

                            opacity_container.appendChild(opacity_label);
                        }

                        opacity_container.appendChild(input_o);
                        container.appendChild(opacity_container);
                    }

                    var extraDivConfig = lyr.get('extraDivConfig');

                    if(extraDivConfig){
                        if(extraDivConfig.function){
                            extraDivConfig.function(extraDivConfig, container, lyrId,lyr);
                        }
                    }


                    li.appendChild(container);
                }
            }

            return li;
        }

        /**
        * **Static** Render all layers that are children of a group.
        * @private
        * @param {ol.Map} map The map instance.
        * @param {ol.layer.Group} lyr Group layer whos children will be rendered.
        * @param {Element} elm DOM element that children will be appended to.
        */

    }, {
        key: 'renderLayers_',
        value: function renderLayers_(map, lyr, elm) {
            var lyrs = lyr.getLayers().getArray().slice().reverse();
            for (var i = 0, l; i < lyrs.length; i++) {
                l = lyrs[i];
                if (l.get('title')) {
                    elm.appendChild(LayerSwitcher.renderLayer_(map, l, i));
                }
            }
        }

        /**
        * **Static** Call the supplied function for each layer in the passed layer group
        * recursing nested groups.
        * @param {ol.layer.Group} lyr The layer group to start iterating from.
        * @param {Function} fn Callback which will be called for each `ol.layer.Base`
        * found under `lyr`. The signature for `fn` is the same as `ol.Collection#forEach`
        */

    }, {
        key: 'forEachRecursive',
        value: function forEachRecursive(lyr, fn) {
            lyr.getLayers().forEach(function (lyr, idx, a) {
                fn(lyr, idx, a);
                if (lyr.getLayers) {
                    LayerSwitcher.forEachRecursive(lyr, fn);
                }
            });
        }

        /**
        * **Static** Generate a UUID  
        * Adapted from http://stackoverflow.com/a/2117523/526860
        * @returns {String} UUID
        */

    }, {
        key: 'uuid',
        value: function uuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : r & 0x3 | 0x8;
                return v.toString(16);
            });
        }

        /**
        * @private
        * @desc Apply workaround to enable scrolling of overflowing content within an
        * element. Adapted from https://gist.github.com/chrismbarr/4107472
        */

    }, {
        key: 'enableTouchScroll_',
        value: function enableTouchScroll_(elm) {
            if (LayerSwitcher.isTouchDevice_()) {
                var scrollStartPos = 0;
                elm.addEventListener("touchstart", function (event) {
                    scrollStartPos = this.scrollTop + event.touches[0].pageY;
                }, false);
                elm.addEventListener("touchmove", function (event) {
                    this.scrollTop = scrollStartPos - event.touches[0].pageY;
                }, false);
            }
        }

        /**
        * @private
        * @desc Determine if the current browser supports touch events. Adapted from
        * https://gist.github.com/chrismbarr/4107472
        */

    }, {
        key: 'isTouchDevice_',
        value: function isTouchDevice_() {
            try {
                document.createEvent("TouchEvent");
                return true;
            } catch (e) {
                return false;
            }
        }

        /**
        * Fold/unfold layer group
        */

    }, {
        key: 'toggleFold_',
        value: function toggleFold_(lyr, li) {
            li.classList.remove(CSS_PREFIX + lyr.get('fold'));
            lyr.set('fold', lyr.get('fold') === 'open' ? 'close' : 'open');
            li.classList.add(CSS_PREFIX + lyr.get('fold'));
        }
    }]);
    return LayerSwitcher;
}(Control);

if (window.ol && window.ol.control) {
    window.ol.control.LayerSwitcher = LayerSwitcher;
}


/**
* Generates a sub-list of layers if the parent layers is a layer group. 
* @private
* @param {ol.layer.Base} lyr Layer to be rendered (should have a title property).
* @param {ul} lyrId Layer Id of the parent layer.  
* @param {ul} lyrTitle Layer Title of the parent layer 
* @param {ul} li List of the currently layers  
* @param {ul} Ul Un ordered list node that will contain the child layers
*/
function GenerateLayerSubList(lyr,lyrId,lyrTitle,li,label,ul)
{
    li.className = 'group';
    // Group folding
    if (lyr.get('fold')) 
    {
        GenerateLayerSubListFold(lyr,li,label)
    }

    if(lyr.get('group_visibility'))
    {
       li = GenerateLayerSubListGroupVisibility(lyr,lyrId, li);
    }

    li = GenerateLayerSubListHeader(lyrTitle,label,li,ul);

    if(lyr.get('toggle_all'))
    {
       ul = GenerateLayerSubListToggelAll(lyr, ul);
    }

    if(lyr.get('hide_toggle')) 
    {
        li.classList.add('hide_toggle');
    }

    return li;
}


/**
* Creates a pair of buttons for each list of layers that will select / deselect all children. 
* @private
* @param {ol.layer.Base} lyr Layer to be rendered (should have a title property).
* @param {ul} Ul that the buttons are going to be appended to, this happens before the child nodes are generated so the buttons are at the top of the list.
*/
 function GenerateLayerSubListToggelAll(lyr, ul)
{
    var sub_li = document.createElement('li');

    let container = document.createElement('div');

    container.className = 'toggle_all_sidebar';

    //currently not in use. 
    var filterLabel = document.createElement('span');


    var buttonOn = document.createElement('input');
    buttonOn.type = 'button'; 
    buttonOn.value = 'Show All';

    buttonOn.onclick = function (e) {
        lyr.setVisible(true);
        lyr.getLayers().forEach(function(l){
            l.setVisible(true);
        })
    };

    var buttonOff = document.createElement('input');
    buttonOff.type = 'button'; 
    buttonOff.value = 'Show None';

    // set all child layers visibility to false. not including the parent layer. 
    buttonOff.onclick = function (e) {
        lyr.getLayers().forEach(function(l){
            l.setVisible(false);
        })
    };

    container.appendChild(filterLabel);
    container.appendChild(buttonOn);
    container.appendChild(buttonOff);
    sub_li.appendChild(container);
    
    ul.appendChild(sub_li);

    return ul;
}

/**
* Creates a checkbox for to enable changing the visibility of the layer - Including Child layers
* @private
* @param {ol.layer.Base} lyr Layer to be rendered (should have a title property).
* @param {ol.layer.Base.Id} lyrId layer Id of the parent layer
* @param {ol.layer.Base.Id} Li list container for the child elements. 
*/
function GenerateLayerSubListGroupVisibility(lyr,lyrId, li)
{
    var input = document.createElement('input');
    input.type = 'checkbox'; 
    input.id = lyrId;
    input.checked = lyr.get('visible');
    input.onchange = function (e) {
        LayerSwitcher.setVisible_(map, lyr, e.target.checked);
    };
    li.appendChild(input);

    lyr.on('change:visible', function(e){
        input.checked = !e.oldValue
    });

    return li;
}

/**
* adds a chevron for styling, adds fold capability to the label. 
* @private
* @param {ol.layer.Base} lyr Layer to be rendered (should have a title property).
* @param {ol.layer.Base.Id} Li list container for the child elements. 
* @param {label} label Lable object for the parent node. 
*/
function GenerateLayerSubListFold(lyr,li,label)
{
    li.classList.add(CSS_PREFIX + 'fold');
    li.classList.add(CSS_PREFIX + lyr.get('fold'));
    label.onclick = function (e) {
        LayerSwitcher.toggleFold_(lyr, li);
    };
}

/**
* adds the header to the tree which includes the visibility checkbox for all sublayers. 
* @private
* @param {ol.layer.Base.Title} lyrTitle Layer to be rendered (should have a title property).
* @param {label} label Lable object for the parent node. 
* @param {label} li List object to append the new layers group to
* @param {label} ul list of sub layers. 
*/
function GenerateLayerSubListHeader(lyrTitle,label,li,ul)
{
    label.innerHTML = lyrTitle;
    li.appendChild(label);
    li.appendChild(ul);

    return li;
}

function GenerateLayerItem(lyr,lyrId,lyrTitle,li,label,ul)
{
    return li;
}

return LayerSwitcher;

})));

