// PopperLabel
//
// Semi reusable ---
//
// Author Daniel Livingston

import Overlay from 'ol/Overlay';

import { v4 as uuidv4 } from 'uuid';

import { Popover } from 'bootstrap';

var $ = require("jquery");

export class PopperLabel {
    constructor(opt_options) {

        const defaults = {
            id: uuidv4(),
            map: null,
            click_function: null,
            content: null,
            detail_content: null,
            goto_function: null,
            info_function:null,
            positioning: null,
            popper_class: null,
            popper_placement:null,
            title: null
        };

        var options = Object.assign({}, defaults, opt_options);

        this.id = options.id
        this.click_function = options.click_function;
        this.map = options.map;
        this.goto_function = options.goto_function;
        this.info_function = options.info_function;
        this.overlay = null;
        this.popover = null;
        this.title = options.title;
        this.content = options.content;
        this.detail_content = options.detail_content;
        this.positioning = options.positioning;
        this.popper_class = options.popper_class;
        this.popper_placement = options.popper_placement;
    }


    renderInfo(extra_popper_class=null){
        let html ='';

        html += '<div id="popper_lable_info_'+this.id+'" class="'+(this.popper_class?this.popper_class:'') + '">';
        //hard code for this project only
        html += '<div style="white-space: nowrap;"><span class="popover_detail hide">Stop: </span>'

        html += this.content;

        html += '</div>';
       
        if(this.detail_content){
            html += '<div class="popover_detail hide '+(this.popper_class?this.popper_class:'') + '" >';
            html += this.detail_content;
            
            if(this.info_function || this.goto_function){
                html += '<div class="popover_controls">';
                if(this.info_function){
                    html += '<i id="'+this.id+'_info_link" class="fas fa-info-circle fa-lg info_link"></i>'
                }

                if(this.goto_function){
                    html += '<i id="'+this.id+'goto_link" class="fas fa-search-plus fa-lg goto_link"></i>'
                }
                html += '</div>';
            }

            html += '</div>';
        }
        html += '</div>';

        return html;
    }

    show(coordinate, options={}){
        
        const popupID = 'popup_' + this.id;

        let html = '<div id="' + popupID + '" class="' + (this.popper_class?' '+this.popper_class:'') + '" '
            + (this.title? ' title="'+this.title+'"':"")+ '></div>';

        $('#'+this.map.getTargetElement().id).parent().append(html);

        const element = document.getElementById(popupID);

        if(!this.overlay){
            this.overlay = new Overlay({
                element: element,
                position: coordinate,
            });

            this.map.addOverlay(this.overlay);
        }
        
        this.popover = new Popover(element, {
            placement: this.popper_placement,
            animation: true,
            html: true,
            content: this.renderInfo(),
            template: options.template?options.template:'<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
        });

        let previous_res = null;
        this.map.on('postrender', e=>{
            const threshold = 30;
            let new_res = e.map.getView().getResolution();

            if(previous_res > threshold && new_res <= threshold){
                $(".popover_detail").removeClass("hide");

            }
            else if(previous_res < threshold && new_res >= threshold){
                $(".popover_detail").addClass("hide");
            }
            this.popover.update();

            previous_res = new_res;
        });

        this.popover.show();
        if(this.click_function){
            $(this.popover.getTipElement()).on('click', this.click_function)
            .on('mouseleave',e=>{//Assumes function mutates element
                this.popover.update();
            });
        }

        if(this.goto_function){
            $('.goto_link', this.popover.getTipElement()).on('click', this.goto_function);
        }

        if(this.info_function){
            $('.info_link', this.popover.getTipElement()).on('click', this.info_function);
        }
    }

    hide(){
        this.popover?.hide();
    }

    showDetail(){
        $('#'+this.popover.getTipElement().id + " .popover_detail").removeClass("hide");
        $('#'+this.popover.getTipElement().id).addClass('top_popper');
    }

    hideDetail(){
        $('#'+this.popover.getTipElement().id + " .popover_detail").addClass("hide");
        $('#'+this.popover.getTipElement().id).removeClass('top_popper');
    }

    update(){
        this.popover.update();
    }
}

export default PopperLabel;