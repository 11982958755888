export var base_map_defs = [{
    "active": true,
    "attribution_text": "Basemap \u00a9 Department of Customer Service 2020",
    "attribution_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer/?f=json",
    "display_name": "NSW Imagery (six)",
    "epsg": 3857,
    "extent_wgs84": "[140.99, -37.53, 153.69, -28.15]",
    "id": 1,
    "matrix_set": "",
    "max_zoom": 23,
    "min_zoom": 0,
    "name": "WMTS_NSW_SIX_IMAGE",
    "ol_type": "XYZ",
    "options": "",
    "order": 1,
    "url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer/tile/{z}/{y}/{x}",
    "visible": false
  },
  {
    "active": true,
    "attribution_text": "Basemap \u00a9 Department of Finance, Services & Innovation 2018",
    "attribution_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Topo_Map/MapServer/?f=json",
    "display_name": "NSW TOPO (six)",
    "epsg": 3857,
    "extent_wgs84": "[140.99, -37.53, 153.69, -28.15]",
    "id": 2,
    "matrix_set": "",
    "max_zoom": 21,
    "min_zoom": 0,
    "name": "WMTS_NSW_SIX_TOPO",
    "ol_type": "XYZ",
    "options": "",
    "order": 2,
    "url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Topo_Map/MapServer/tile/{z}/{y}/{x}",
    "visible": false
  },
  {
    "active": true,
    "attribution_text": "Basemap \u00a9 Department of Finance, Services & Innovation 2018",
    "attribution_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Base_Map/MapServer/?f=json",
    "display_name": "NSW Base (six)",
    "epsg": 3857,
    "extent_wgs84": "[140.99, -37.53, 153.69, -28.15]",
    "id": 2,
    "matrix_set": "",
    "max_zoom": 21,
    "min_zoom": 0,
    "name": "WMTS_NSW_SIX_TOPO",
    "ol_type": "XYZ",
    "options": "",
    "order": 2,
    "url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Base_Map/MapServer/tile/{z}/{y}/{x}",
    "visible": false
  },
  {
    "active": true,
    "attribution_text": "Basemap \u00a9 Esri, Maxar, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
    "attribution_url": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer?f=json",
    "display_name": "Esri World Image",
    "epsg": 3857,
    "id": 3,
    "matrix_set": "",
    "max_zoom": 23,
    "min_zoom": 0,
    "name": "WMTS_ARCGIS_WORLD",
    "ol_type": "XYZ",
    "options": "",
    "order": 3,
    "url": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    "visible": true
  }
]