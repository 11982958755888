export var other_map_defs = [{
    "active": true,
    "attribution_text": "\u00a9 Department of Finance, Services & Innovation 2018",
    "attribution_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Cadastre/MapServer/?f=pjson",
    "display_name": "Cadastre",
    "epsg": 3857,
    "legend_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Cadastre/MapServer/legend?f=pjson",
    "legend_layer_ids": new Set([4]),
    "maxResolution": 40,
    "id": 1,
    "name": "ARC_NSW_SIX_Cadastre",
    "ol_type": "ArcGISREST",
    "params": {
			"TRANSPARENT":"true",
			"OPACITY":1,
			"LAYERS":"show:4",
			"f":"image",
			"FORMAT":"png"
		},
    "order": 1,
    "url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Cadastre/MapServer",
    "visible": false
  },{
  //   "active": true,
  //   "attribution_text": "\u00a9 Department of Finance, Services & Innovation 2018",
  //   "attribution_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Administrative_Boundaries/MapServer/?f=pjson",
  //   "display_name": "Administrative Boundaries",
  //   "epsg": 3857,
  //   "legend_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Administrative_Boundaries/MapServer/legend?f=pjson",
  //   // "legend_layer_ids": new Set([4]),
  //   "id": 2,
  //   "name": "ARC_NSW_SIX_Admin",
  //   "ol_type": "ArcGISREST",
  //   "params": {
	// 		"TRANSPARENT":"true",
	// 		"OPACITY":1,
	// 		// "LAYERS":"show:4",
	// 		"f":"image",
	// 		"FORMAT":"png"
	// 	},
  //   "order": 2,
  //   "url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Administrative_Boundaries/MapServer",
  //   "visible": false
  // },{
    "active": true,
    "attribution_text": "\u00a9 Department of Finance, Services & Innovation 2018",
    "attribution_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Administrative_Boundaries/MapServer/?f=pjson",
    "display_name": "Administrative Boundaries",
    "epsg": 3857,
    "legend_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Administrative_Boundaries/MapServer/legend?f=pjson",
    "legend_layer_ids": new Set([1,5,6]),
    "id": 2,
    "name": "ARC_NSW_SIX_Admin",
    "ol_type": "ArcGISREST",
    "params": {
			"TRANSPARENT":"true",
			"OPACITY":1,
			"LAYERS":"show:1,5,6",
			"f":"image",
			"FORMAT":"png"
		},
    "order": 2,
    "url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Administrative_Boundaries/MapServer",
    "visible": false
  },
  
]


