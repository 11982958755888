import * as olProj from 'ol/proj';

import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';


//GDA2020
proj4.defs("EPSG:7855","+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs("EPSG:7856","+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs("EPSG:7857","+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
//GDA94
proj4.defs("EPSG:28355","+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs("EPSG:28356","+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs("EPSG:28357","+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

//NSW Lambert 94
proj4.defs("EPSG:3308","+proj=lcc +lat_1=-30.75 +lat_2=-35.75 +lat_0=-33.25 +lon_0=147 +x_0=9300000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

register(proj4);


export var mapProjEPSG = "EPSG:3857"
// var map_proj = olProj.get(mapProjEPSG);

// if(!map_proj.getWorldExtent()){//Aus
//     map_proj.setWorldExtent([112.85, -43.7, 153.69, -9.86]);
// }

// var projExt = olProj.transformExtent(map_proj.getWorldExtent(), olProj.get('EPSG:4326'), map_proj);

// map_proj.setExtent(projExt);
