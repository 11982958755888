
import XYZ from 'ol/source/XYZ';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';
import BingMaps from 'ol/source/BingMaps';

import WMTSTileGrid from 'ol/tilegrid/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';

import { Attribution } from 'ol/control';

import ImageWMS from 'ol/source/ImageWMS'

import {ImageArcGISRest, TileArcGISRest} from 'ol/source';
import {Image as ImageLayer, Tile as TileLayer} from 'ol/layer';

import $ from "jquery";

const { other_map_defs } = require('./OtherLayers');


const parserWMTS = new WMTSCapabilities();

const default_epsg = 3857;
const default_min_zoom = 0;
const default_max_zoom = 18;

const legendCache = {}

export async function populateLayerGroup(definitions_array, out_layer_group, callback){
    var layers = out_layer_group.getLayers();

    for(let i=0; i<definitions_array.length; i++){
        let definition = definitions_array[i];
        if(definition.active){
            if(definition.ol_type == "XYZ"){
                buildXYZLayer(definition).then(function(l){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
            else if(definition.ol_type == 'WMTS'){
                buildWMTSLayer(definition,function(l){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
            else if (definition.ol_type == 'Bing'){
                buildBingLayer(definition).then(function(l){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
            else if (definition.ol_type == 'WMS'){
                buildWMSLayer(definition).then(function(l){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
            else if (definition.ol_type == 'ArcGISREST'){
                buildArcGISREST(definition).then(function(l){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
        }
    }
};


function addLayerToGroup(layer, layerGroup, callback){
    try{
        layerGroup.push(layer);
        callback();
    }
    catch(e){
        console.log(e)
    }
}

async function getCopyright(attribution_url,layer){
    // fetch(attribution_url).then(function(response) {
    //     console.log(response.status);
    //     return response.text();
    // }).then(r=>{
    //     const cr = JSON.parse(r);
    //     if(r.copyrightText){
    //         const attrib = new Attribution({html:r.copyrightText});
    //         layer.getSource().setAttributions(attribution);
    //     }
    // });
}


async function buildWMTSLayerFromCapabilities(definition, options, callback){
    fetch(definition.capabilities).then(function(response) {
        return response.text();
    }).then(function(xml){
        if(xml){
            var result = parserWMTS.read(xml);
            var wmtsOptions = optionsFromCapabilities(result, {
                format: (options.format?options.format:'image/png'),
                layer: options.layer,
                matrixSet: 'EPSG:'+(definition.epsg?definition.epsg: default_epsg),
            });
            var tileLayer = new TileLayer({
                enableOpacitySliders : (options.opacity?true:false),
                source: new WMTS(wmtsOptions),
                title: definition.display_name,
                type: (definition.type?definition.type:'base'),
                updateWhileAnimating: true,
 updateWhileInteracting: true,
            });
            buildGroupLayer(tileLayer, definition, callback);
        }
        else{
            throw('Bad Capabilities Statment');
        }
    }).catch(err => {
        console.error('fetch failed', err);
    });
}

async function buildWMTSLayerFromdefinitioninition(definition, callback){
    var layer = new TileLayer({
        title: definition.display_name,
        type: (definition.type?definition.type:'base'),
        enableOpacitySliders : (opacity?true:false),
        source: new WMTS({
            attributions: (definition.attribution_text?definition.attribution_text:''),
            // crossOrigin : "anonymous",
            format: (o.format?o.format:'image/png'),
            layer: (o.layer?o.layer:''),
            matrixSet: (definition.matrix_set?definition.matrix_set:''),
            minZoom: (definition.min_zoom?definition.min_zoom:default_min_zoom),
            maxZoom: (definition.min_zoom?definition.min_zoom:default_max_zoom),
            projection : 'EPSG:'+(definition.epsg?definition.epsg:default_epsg),
            tileGrid: new WMTSTileGrid(o.tilegrid),
            url: definition.url,
        }),
        updateWhileAnimating: true,
 updateWhileInteracting: true,
    });
    
    if(layer){
        buildGroupLayer(layer, definition, callback);
    }
}


function buildGroupLayer(layer, definition, callback){
    if(typeof definition.visible !== 'undefinitionined'){
        layer.setVisible(definition.visible);
    }
    if(definition.attribution_url){
        getCopyright(definition, layer)
    }
    callback({
        DisplayName : definition.display_name,
        layer: layer,
    })
}

export async function buildWMTSLayer(definition, callback){
    const o = JSON.parse(definition.options);

    if(definition.capabilities && o && o.layer){
        buildWMTSLayerFromCapabilities(definition, o, callback);
    }
    else{
        buildWMTSLayerFromdefinitioninition(definition, callback);
    }
};

async function buildXYZLayer(definition){
        try{
            var layer = new TileLayer({
                title: definition.display_name,
                type: 'base',
                source: new XYZ({
                    url: definition.url,
                    projection : 'EPSG:'+(definition.epsg?definition.epsg:default_epsg),
                    attributions: (definition.attribution_text?definition.attribution_text:''),
                    // crossOrigin : "anonymous",
                    minZoom: (definition.min_zoom?definition.min_zoom:default_min_zoom),
                    maxZoom: (definition.min_zoom?definition.min_zoom:default_max_zoom)
                }),
                updateWhileAnimating: true,
 updateWhileInteracting: true,
            });

            if(layer){
                if(definition.attribution_url) getCopyright(definition, layer);

                return {
                    DisplayName : definition.display_name,
                    layer: layer
                };
            }
        }
        catch(e){
            console.log(e);
        }

        return null;
}

async function buildBingLayer(definition){
    try{
        var layer = new TileLayer({
            title: definition.display_name,
            type: 'base',
            visible: definition.visible,
            preload: Infinity,
            source: new BingMaps({
                key: definition.bing_key,
                imagerySet: definition.bing_imagry_set,
            }),
            updateWhileAnimating: true,
 updateWhileInteracting: true,
        });

        if(layer){
            // if(definition.attribution_url) getCopyright(definition, layer);
            return {
                DisplayName : definition.display_name,
                layer: layer
            };
        }
    }
    catch(e){
        console.log(e);
    }

    return null;
}


async function buildWMSLayer(def){
	try{
		var o = JSON.parse(def.options);

		var layer = new ImageLayer({
			title: def.display_name,
			extent: (o.extent?o.extent:(def.extent?def.extent:[])),
			// type: 'base',
			source: new ImageWMS({
				url: def.url,
				layer: (o.layer?o.layer:''),
				format: (o.format?o.format:'image/png'),
				projection : 'EPSG:'+(def.epsg?def.epsg:3857),
				attributions: (def.attribution_text?def.attribution_text:''),
				params: o.params?o.params:{},
				ratio: (o.ratio?o.ratio:1),
				serverType: (o.serverType?o.serverType:'geoserver'),
				// crossOrigin : "anonymous"
                
			}),
            updateWhileAnimating: true,
 updateWhileInteracting: true,
			zIndex:1
		});


		if(layer){
			if(def.attribution_url){
				getCopyright(def, layer)
			}

			return {
                DisplayName : def.display_name,
                layer: layer
            };
		}
	}
	catch(e){
		console.log(e);
	}

	return null;
}

async function buildArcGISREST(def){
    try{
		let layer = new TileLayer({
			title: def.display_name,
			// extent: (o.extent?o.extent:(def.extent?def.extent:[])),
			// type: 'base',
			source: new TileArcGISRest({
				url: def.url,
                params: def.params?def.params:{},
				projection : 'EPSG:'+(def.epsg?def.epsg:3857),
				attributions: (def.attribution_text?def.attribution_text:''),
				ratio: 1,
                // hidpi:true
				// crossOrigin : "anonymous"
			}),
            minResolution: (def.minResolution?def.minResolution:0),
            maxResolution: (def.maxResolution?def.maxResolution:Number.MAX_SAFE_INTEGER),
            visible: def.visible?def.visible:false,
			zIndex:1,
            updateWhileAnimating: true,
            updateWhileInteracting: true,
            enableOpacitySliders: true,
            opacityLabel: 'Layer Opacity',
		});

        if(def.legend_url){
            layer.set('extraDivConfig',{"function":arcGISRESTLegend, "def":def});
        }

		if(layer){
			if(def.attribution_url){
				getCopyright(def, layer)
			}

			return {
                DisplayName : def.display_name,
                layer: layer
            };
		}
	}
	catch(e){
		console.log(e);
	}

	return null;
}

async function arcGISRESTLegend(config, container, lyrId,lyr){
    let html = '';
    if(legendCache[lyrId]){
        html = legendCache[lyrId];
    }
    else{
        if(config.def && config.def.legend_url){
            try{
                const r = await fetch(config.def.legend_url);
                const j = await r.json();
                
                if(!j.layers) return;

                let limit_set = false;
                if(config.def.legend_layer_ids && config.def.legend_layer_ids.size > 0){
                    limit_set = config.def.legend_layer_ids
                }

                html = '<div id="'+lyrId+'_legend_block" class="legend_block">';

                j.layers.forEach(l=>{
                    try{
                        if(limit_set === false || limit_set.has(l.layerId)){
                            html += '<div class="legend_layer_name"> <input type="checkbox" id="legend'+l.layerId+'" name="'+l.layerName+'" value="'+l.layerId+'"><label for="'+l.layerName+'">'+l.layerName+'</label></div>';
                            l.legend.forEach(record=>{
                                html += '<div class="legend_row">';
                                    html += '<div class="legend_row_icon" style="background: url(data:'+record.contentType+';base64,'+record.imageData+'); width:'+record.width+'; height:'+record.height+';"></div>';
                                    html += '<div class="legend_row_heading">'+record.label+'</div>';
                                html += '</div>';
                            });
                        }
                    }
                    catch(e){
                        console.log(e);
                    }
                });

                html += '</div>';
            }catch(e){}
        }

        legendCache[lyrId] = html;
    }

    $(container).append(html);
    
    otherLayersCheckbox(config.def,lyr);

}

/**
 * Check currently active sub-layers from the Administitive Boundaries layer
 * Add on change to the checkboxes to send ArcGis rest query to update layers
 * @param {*} other_map_defs definition at run time for the Other_map_layers  
 * @param {*} lyr referece to the Open-layer layer. 
 * @returns {null}
 */
function otherLayersCheckbox(other_map_defs,lyr)
  {

      if(other_map_defs.display_name == "Administrative Boundaries")
      {
        var array = other_map_defs.params.LAYERS.replace("show:","").split(",");

        var legendIds = other_map_defs.legend_layer_ids;
    
        legendIds.forEach( id => {
            if(array.includes(id.toString()))
            {
                $( "#legend"+id ).prop( "checked", true );
            }

            $( "#legend"+id ).on("change",function(e) {
                let value = e.target.value;

                console.log(lyr);

                let source = lyr.getSource();

                let params = source.params_.LAYERS;
                let showArray = params.replace("show:","").split(",");

               

                if($(this).prop("checked") == true){
  
                    if(!showArray.includes(value))
                    {
                        showArray.push(value);
                    }
                    
                }
                else if($(this).prop("checked") == false){

                    if(showArray.includes(value))
                    {
                        if(showArray.length == 1)
                        {
                            $(this).prop( "checked", true );
                            return;
                        }
                        else
                        {
                            const index = showArray.indexOf(value);
                            if (index > -1) {
                                showArray.splice(index, 1);
                            }
                        }
                     
                    }
                }

                let showString = "";

                if(showArray.length >= 1 && showArray[0] != "")
                {
                    lyr.setVisible(true);
                    showString = "show:";

                    showArray.forEach(s => {
                        showString += s + ',';
                    });
    
                    showString = showString.replace(/,\s*$/, "");
    
                        lyr.getSource().updateParams({
                            'LAYERS': showString,
                        });
    
                }
                else{
                    lyr.setVisible(false);
                }

              
                    
                lyr.changed();

              });
        });



      }

  }
