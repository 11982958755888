// import regeneratorRuntime from "regenerator-runtime";
import Sidebar from 'sidebar-v2/js/ol5-sidebar';


export class SidebarPatch extends Sidebar{
    constructor(...args) {
        super(...args);
        this._openCallback = null;
        this._closeCallback = null;
    }

    setOpenCallback = function(callback){
        if(callback){
            this._openCallback = async function(...args){callback(...args);};
        }
    };

    setCloseCallback = function(callback){
        if(callback){
            this._closeCallback = async function(...args){callback(...args);};
        }
    };

    open = function(id) {
        var i, child;
    
        var openID = null;
        var closeID = null;
        // hide old active contents and show new content
        for (i = this._panes.length - 1; i >= 0; i--) {
            child = this._panes[i];
            if (child.id == id){
                child.classList.add('active');
                openID = id;

            }
            else if (child.classList.contains('active'))
                child.classList.remove('active');
        }
    
        // remove old active highlights and set new highlight
        for (i = this._tabitems.length - 1; i >= 0; i--) {
            child = this._tabitems[i];
            if (child.querySelector('a').hash == '#' + id)
                child.classList.add('active');
            else if (child.classList.contains('active'))
                child.classList.remove('active');
                closeID = child.id;
        }
    
        // open sidebar (if necessary)
        if (this.element.classList.contains('collapsed')) {
            this.element.classList.remove('collapsed');
        }
        
        if(this._openCallback){
            this._openCallback(openID, closeID);
        }  

        return this;
    };

    close = function() {
        // remove old active highlights
        for (var i = this._tabitems.length - 1; i >= 0; i--) {
            var child = this._tabitems[i];
            if (child.classList.contains('active')){
                child.classList.remove('active');
            }
        }
    
            // hide old active contents and show new content
        for (i = this._panes.length - 1; i >= 0; i--) {
            child = this._panes[i];
            if (child.classList.contains('active'))
                child.classList.remove('active');
        }
    
        // close sidebar
        if (!this.element.classList.contains('collapsed')) {
            this.element.classList.add('collapsed');
        }
    
        if(this._closeCallback){
            this._closeCallback();
        }
    
        return this;
    };

}